export const navigation = [
    {
        "text": "About",
        "items": [{ "text": "About " }, { "text": "18D Coin" }, { "text": "Rules" }]
    }, {
        "text": "Crypto Trend",
        "items": [{ "text": "18Digits Voting", "link": "/voting" }, { "text": "Compare Crypto" }]
    }, {
        "text": "Airdrops",
        "link": "profile/airdrops",
    }, {
        "text": "NFTs",
        "items": [{ "text": "18Digits NFT Collections" }, { "text": "NFT Listings" }]
    }, {
        "text": "News"
    }, {
        "text": "Analytics"
    }, {
        "text": "B2B"
    }, {
        "text": "Contacts"
    }, {
        "text": "Profile",
        "link": "/profile/rewards",
        "disabled": true
    },
]

export const navigationMobile =[
    { "text": "18Digits Voting", "link": "/voting" },
    { "text": "Profile", "link": "/profile/votes" }
]