import React from 'react'
import { HeaderComponent } from '@mod';
import MainRoutes from './main.router'

const MainComponet = () => {
    return (
        <div>
            <HeaderComponent />
            <MainRoutes />
        </div>
    )
}

export default MainComponet

