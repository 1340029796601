import React, { memo, useState } from 'react'
import { 
    FaWhatsapp, 
    FaFacebookMessenger, 
    FaTwitter,
    FaLinkedinIn
} from 'react-icons/fa';

import * as SC from './rewards.styled'
import { Title, SubTitle } from '@core-primitives'
import { CoinLight, SponserBanner } from 'core-img'
import ClaimPopUpComponent from './claimPopup'
import { GetVotedRewardsFromState } from '../../voting/cryptoDetails/cryptoDetails.selector';

const MyRewardsComponent = () => {
    const [showPopUp, setShowPopUp] = useState<boolean>(false);
    const loginPopUp = () => {
        setShowPopUp(!showPopUp);
    };
    const rewardCount = GetVotedRewardsFromState()
    return (
        <SC.RewardsContainer>
            <SC.RewardsClaimContainer md='6'>
                <Title>
                    My 18Digits Rewards
                </Title>
                <SC.RewardsClaimWrapper>
                <SC.RewardsCoinImage />
                <Title>
                    {rewardCount ? rewardCount: 50}
                </Title>
                </SC.RewardsClaimWrapper>
                <SC.RewardsClaimButton type={'info'} onClick={loginPopUp}>
                    Claim
                </SC.RewardsClaimButton> 
                <ClaimPopUpComponent 
                    setShowPopup={showPopUp} 
                    onClose={loginPopUp}
                    rewardCount={rewardCount}/>             
                <SC.RewardsMembershipText>
                    <a href="#">Claim 250+ 18D For PREMIUM Membership</a>
                </SC.RewardsMembershipText>
                <SC.RewardsMembershipText>
                    <a href="#">Claim 2000+ For 18Digits HERO Membership</a> 
                </SC.RewardsMembershipText>
                <SubTitle>
                    REFERRAL LINK SHARE
                </SubTitle>
                <SC.RewardsRefferalShare>
                    <FaWhatsapp/>
                    <FaFacebookMessenger/>
                    <FaTwitter/>
                    <FaLinkedinIn/>
                </SC.RewardsRefferalShare>
            </SC.RewardsClaimContainer>
            <SC.RewardsAddContainer>
                <Title>
                    Sponsored
                </Title>
                <SC.RewardsSponserImage/>
            </SC.RewardsAddContainer>
        </SC.RewardsContainer>
    )
}

export default memo(MyRewardsComponent)
