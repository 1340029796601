import styled, { css } from "styled-components";
import { Title, SubTitle } from '@core-primitives'

export const VoteTableContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;   
`;
export const VoteTableTitle = styled(Title)`
SC.VoteTableTitle
    flex-direction: column;
    align-items: center; 
    padding-bottom: 1rem;  
`;