import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './root.reducer';
import rootEpic from './root.epic';

const epicMiddleware:any = createEpicMiddleware();
const enhanceDevTool:any = composeWithDevTools({});

export default createStore(
  rootReducer,
  enhanceDevTool(applyMiddleware(epicMiddleware)),
);
epicMiddleware.run(rootEpic);

