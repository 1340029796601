import { useRoutes } from 'react-router-dom';
import HomeComponent from '../../pages/home';
import VotingComponent from '../../pages/voting';
import ProfileComponent from '../../pages/profile';
import ProfileRoutes from '../../pages/profile/profile.router';

const MainRoutes = ()=>{
    const routes = useRoutes([
        {
            path:'/',
            element: <VotingComponent/>,
        },
        {
            path:'/voting',
            element: <VotingComponent/>,
        },
        {
            path:'/profile',
            element: <ProfileComponent/>,
            children: ProfileRoutes(),
        },
        {
            path:'*',
            element: <div>Error</div>,
        },
    ])
    return routes;
}

export default MainRoutes;