import styled, { css } from "styled-components";
import { Title, SubTitle } from '@core-primitives'
import { ButtonComponent} from "@core-primitives";

export const SearchVoteContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;   
`;
export const SearchVoteTitle = styled(Title)`
    flex-direction: column;
    align-items: center; 
    padding-bottom: 1rem; 
    ${({ theme }) => css`
    @media ${theme.tablet} {
        padding: 0;
       }
    `} 
`;
export const AutoCompleteContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width:80%;
    display: flex;  
    span{
        width: 100%;
    }
    ${({ theme }) => css`
    @media ${theme.tablet} {
        flex-direction: column;
       }
    `}
`;
export const SearchTextContainer = styled.div`
    width:80%;
    padding: 1rem 0;
`;
export const SearchVoteSubmitButton = styled(ButtonComponent)`
        width: auto;
        ${({ theme }) => css`
        @media ${theme.tablet} {
            width: 100%;
           }
        `}
`;

export const VoteAddButton = styled(ButtonComponent)`
    margin-left: 10px;
    ${({theme})=> css`

    `}
`;