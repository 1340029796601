import { useEffect, memo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Grid, GridColumn } from "@progress/kendo-react-grid";

import { WindowData } from './constant'
import { PopupAutoComplete } from '@core-primitives';
import { GetSelectedCyptoFromReducer, GetAllCyptoFromReducer } from '../../../voting/cryptoDetails/voteWindow/selectedCryptoList.selector';
import * as SC from './serachVote.styled'
import { getAllCyptoList, setVote, updateRewards } from '../../../voting/voting.actions';

const SearchVoteComponent = ({ setTableData, tableData }: any) => {
    const [autoCompleteSearchData, setAutoCompleteSearchData] = useState<any[]>([])
    const [autoCompletValue, setAutoCompletValue] = useState<string>('')
    const dispatch = useDispatch();
    const rewardValue = 5;
    const voteData: any = GetSelectedCyptoFromReducer();
    const allCyptopList: any = GetAllCyptoFromReducer()
    const filteredData = Object.values(voteData).map((item: any) => ({ rank: item.rank, 'coinName': item['coinName'] }))
    const updetedAutoCompleteData: any = allCyptopList.filter(({ coinName }: any) => !filteredData.find(({ coinName: filterdCoinName }: any) => coinName == filterdCoinName))

    useEffect(() => {
        dispatch(getAllCyptoList())
        setAutoCompleteSearchData(updetedAutoCompleteData.map(({ coinName }: any) => coinName))
    }, [JSON.stringify(updetedAutoCompleteData)])
    const onChangeAutoComplete = ({ value }: any) => {
        setAutoCompletValue(value)
    }
    const submitRewards = () => {
        const rewardData = tableData.map(({ coinName }: any) => allCyptopList.find(({ coinName: rewardCoinName }: any) => coinName == rewardCoinName))
        rewardData.map((data: any) => dispatch(setVote({ key: data.coinName, value: data })))
        dispatch(updateRewards(rewardValue))
    }
    const addVoteItem = () => {
        const selectedCrypto: any = allCyptopList.filter(({ coinName }: any) => coinName == autoCompletValue)
        setAutoCompleteSearchData(autoCompleteSearchData.filter((name) => name != autoCompletValue))
        setTableData([{ rank: selectedCrypto[0].rank, coinName: selectedCrypto[0].coinName, rating: selectedCrypto[0].votingDominance }])
        setAutoCompletValue('')
    }
    return (
        <SC.SearchVoteContainer>
            <SC.SearchVoteTitle>
                Search & Vote
            </SC.SearchVoteTitle>
            <SC.AutoCompleteContainer>
                <PopupAutoComplete
                    placeholder={'Eg : 18digits'}
                    data={autoCompleteSearchData}
                    onClose={onChangeAutoComplete}
                    value={autoCompletValue}
                />
                <SC.VoteAddButton type={'highlight'} onClick={addVoteItem}>
                    Add
                </SC.VoteAddButton>
            </SC.AutoCompleteContainer>
            <SC.SearchTextContainer>
                Users are allowed to change their Top 18 cryptocurrencies whenever they choose.
                Before adding a new coin to your list instead of another, first,
                you need to remove the old selection by clicking on the pink icon on the right of the table.
                <br />
                <br />
                The field will remain empty. To place another cryptocurrency in that empty field,
                simply search for its name or ticker and click on the “Add” button beside it.
                <br />
                <br />
                To submit your votes (and that’s vital for getting rewarded), click the button below this text.
            </SC.SearchTextContainer>
            <SC.SearchVoteSubmitButton type={'highlight'} onClick={submitRewards}>
                Submit Votes
            </SC.SearchVoteSubmitButton>
        </SC.SearchVoteContainer>
    )
}

export default memo(SearchVoteComponent)
