import { 
    SET_DATA,
    SET_VOTE, 
    GET_DATA, 
    SET_ALL_DATA,
    SET_REWARD_DATA,
    UPDATE_REWARD_DATA
    } from "./voting.actions";
import { VotingReducerProps } from '@core-interface'


const initState: VotingReducerProps = {
    cryptoList: [],
    allcrypto: [],
    selectedList: {},
    myVote: 0,
};

const votingReducer = (state = initState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case GET_DATA:
            return {
                ...state,
                cryptoList: payload.page === 1 ? [] : state.cryptoList,
            };
        case SET_DATA:
            return {
                ...state,
                cryptoList: [...state.cryptoList, ...payload],
            };
        case SET_ALL_DATA:
            return {
                ...state,
                allcrypto: payload,
            };
        case SET_VOTE:
            return {
                ...state,
                selectedList: { ...state.selectedList, [payload.key]: payload.value },
            };
        case SET_REWARD_DATA:
            return {
                ...state,
                myVote: payload ,
            };
        case UPDATE_REWARD_DATA:
            return {
                ...state,
                myVote: state.myVote + payload ,
            };
        default:
            break;
    }
    return state;
}

export default votingReducer;