import React, { memo, FC } from 'react'
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { PopupWindowProps } from '@core-interface'
import { PopupWindow, ButtonComponent } from '@core-primitives'
import { CoinWhite } from 'core-img'
import CailmFormComponent from './cailmForm'
import * as SC from './cailmPopup.styled'
import { dropdownData } from "./constant"



const ClaimPopUpComponent: FC<PopupWindowProps> = ({ setShowPopup, onClose, rewardCount }) => {
    const IconRender = ({value}: any) => {
        return(
            <SC.DropDownIcon icon={value}/>
        )}
    const valueRender = (
        element: React.ReactElement<HTMLSpanElement>,
        value: any
    ) => {
        if (!value) {
            return element;
        }
        const children = [
            <SC.DropDownItemContainer>
                <IconRender value={value.id}/><div>{value.coinName}</div>
            </SC.DropDownItemContainer>
        ];
        return React.cloneElement(element, { ...element.props }, children);
    };
    const itemRender = (li: any, itemProps: any) => {
        let { dataItem } = itemProps;
        if (!dataItem)
            dataItem = itemProps;
        const itemChildren = <SC.DropDownItemContainer>
                                <IconRender value={dataItem.id}/><div>{dataItem.coinName}</div>
                             </SC.DropDownItemContainer>
        return React.cloneElement(li, li.props, itemChildren);
    };
    return (
        <PopupWindow 
            setShowPopup={setShowPopup} 
            onClose={onClose} 
            initialTop={10} 
            initialWidth={900}
            initialHeight={650}
            >
            <SC.ClaimPopupContainer>
                <div className={'leftContent'}>
                <SC.ClaimPopupImageContainer>
                    <SC.ClaimPopupImage />
                </SC.ClaimPopupImageContainer>
                <SC.ClaimPopupTitle>
                    Withdraw to Coin
                </SC.ClaimPopupTitle>
                <SC.ClaimPopupText>
                    Available 18D for Claim : {rewardCount ? rewardCount : 50}
                </SC.ClaimPopupText>
                <SC.ClaimDropDownContainer>
                    <DropDownList
                        defaultValue={dropdownData[0]}
                        data={dropdownData}
                        valueRender={valueRender}
                        itemRender={itemRender}
                    />
                </SC.ClaimDropDownContainer>
                <SC.ClaimTextContainer>
                    Claim Fee: BNB 0.0015
                </SC.ClaimTextContainer>
                    <CailmFormComponent />
                </div>
                <div className={'rightContent'}>
                <SC.ClaimInfomationContainer>
                    <ul>
                        <li>
                            Please connect your Metamask to the correct blockchain before attempting to connect your wallet.
                            Otherwise, you will be blocked for 24 hours.
                        </li>
                        <li>
                            Membership upgrades may take a few minutes to complete please be patient.
                        </li>
                        <li>
                            If your points have not arrived in your wallet within 1 hour,<br />
                            please send us an email with the following details:
                        </li>
                    </ul>
                    <ol>
                        <li>
                            Account email address
                        </li>
                        <li>
                            BSC wallet address
                        </li>
                        <li>
                            ransaction id from Binance Smart Chain explorer
                        </li>
                    </ol>
                </SC.ClaimInfomationContainer>
                <SC.ClaimButtonContainer style={{ width: '90%', paddingBottom: '1rem' }}>
                    <ButtonComponent type={'highlight'} >
                        Get Support
                    </ButtonComponent>
                </SC.ClaimButtonContainer>
                </div>
            </SC.ClaimPopupContainer>
        </PopupWindow>
    )
}

export default memo(ClaimPopUpComponent)
