

const inputValidator = (value: any) => (!value ? "* required" : "");

export const dropdownData= [
  { coinName:'18Digits', id:'digits'},
  { coinName:'Etherium', id:'ethereum'},
  { coinName:'Bitecoin', id:'bitcoin'}
]

export const fieldElementProps = ({ FormInput}: any) => {
  return (
    {
      connetMetaMask: {
        id: 'metaMaskAddress',
        label: 'metaMaskAddress',
        name: 'metaMaskAddress',
        component: FormInput,
        placeholder: 'BEP-20 Token Wallet Address',
        // validator: inputValidator,
      },
      numberOfPoints: {
        id: 'numberOfPoints',
        label: 'numberOfPoints',
        name: 'numberOfPoints',
        component: FormInput,
        placeholder: 'Enter Number of Points',
        // validator: inputValidator,
      },
      securityCode: {
        id: 'securityCode',
        label: 'securityCode',
        name: 'securityCode',
        component: FormInput,
        placeholder: 'Enter Security Code"',
        // validator: inputValidator,
      }
    })
}