import React from 'react'
import { ButtonComponent, Title } from '@core-primitives'
import * as SC from './learningDetails.styled'
import { MainLogo } from '@core'

const CryptoLearning = () => {
    return (
        <>
            <SC.LessonContainer>
                <SC.LessonData>
                    <Title>How It Works</Title>
                    <SC.VedioContainer>
                        <SC.VedioLogo src={MainLogo} />
                        <SC.VedioText>
                            Explainer Vedio
                        </SC.VedioText>
                    </SC.VedioContainer>
                </SC.LessonData>
            </SC.LessonContainer>
            <SC.LessonContainer>
                <SC.LessonData>
                    <Title>Latest voting Reports</Title>
                    <SC.VedioContainer>
                        <SC.VedioLogo src={MainLogo} />
                        <SC.VedioText>
                                voting report vedio
                        </SC.VedioText>
                    </SC.VedioContainer>
                </SC.LessonData>
            </SC.LessonContainer>
            <ButtonComponent type={'info'}>More Repaorts</ButtonComponent>
        </>
    )
}

export default CryptoLearning
