import { useEffect, memo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { PopupAutoComplete, PopupWindow, ButtonComponent } from '@core-primitives';

import * as SC from '../cryptoDetails.styled'
import { WindowData } from './voteWindow.constant'
import { GetSelectedCyptoFromReducer, GetAllCyptoFromReducer } from './selectedCryptoList.selector';
import {
    getAllCyptoList,
    setVote,
    updateRewards,
    getCyptoList,
    getRewards
} from '../../voting.actions';

const VoteContent = ({onSubmit}: any) => {
    const [tableData, setTableData] = useState<any[]>([])
    const [autoCompletValue, setAutoCompletValue] = useState<string>('')
    const [autoCompleteSearchData, setAutoCompleteSearchData] = useState<any[]>([])
    const dispatch = useDispatch();
    const rewardValue = 5;
    const voteData: any = GetSelectedCyptoFromReducer();
    const allCyptopList: any = GetAllCyptoFromReducer()
    const filteredData = Object.values(voteData).map((item: any) => ({ rank: item.rank, 'coinName': item['coinName'] }))
    const updetedAutoCompleteData: any = allCyptopList.filter(({ coinName }: any) => !filteredData.find(({ coinName: filterdCoinName }: any) => coinName == filterdCoinName))

    useEffect(() => {
        dispatch(getAllCyptoList())
        setTableData(filteredData)
        setAutoCompleteSearchData(updetedAutoCompleteData.map(({ coinName }: any) => coinName))
    }, [JSON.stringify(updetedAutoCompleteData)])

    const onChangeAutoComplete = ({ value }: any) => {
        setAutoCompletValue(value);
    }
    const submitRewards = () => {
        const rewardData = tableData.map(({ coinName }: any) => allCyptopList.find(({ coinName: rewardCoinName }: any) => coinName == rewardCoinName))
        rewardData.map((data: any) => dispatch(setVote({ key: data.coinName, value: data })))
        dispatch(updateRewards(rewardValue))
        onSubmit()
    }
    const addVoteItem = () =>{
        const selectedCrypto = allCyptopList.filter(({ coinName }: any) => coinName == autoCompletValue)
        setAutoCompleteSearchData(autoCompleteSearchData.filter((name) => name != autoCompletValue))
        setTableData(prev => [...prev, { rank: selectedCrypto[0].rank, coinName: selectedCrypto[0].coinName }])
        setAutoCompletValue('')
    }
    return (
            <>
                <PopupAutoComplete placeholder={'Eg : 18digits'}
                    data={autoCompleteSearchData}
                    onClose={onChangeAutoComplete}
                    value={autoCompletValue}
                />
                <SC.VoteAddButton type={'highlight'} onClick={addVoteItem}> 
                    Add  
                </SC.VoteAddButton>   
                <Grid data={tableData}>
                    <GridColumn field={WindowData[1]} title={WindowData[1]} />
                    <GridColumn field={WindowData[0]} title={WindowData[0]} />
                </Grid>
                <SC.ReawrfInfoText>
                    You Will Reward 5 Coins For every Submit
                </SC.ReawrfInfoText>
                <SC.SubmitButton type={'highlight'} onClick={submitRewards}> Submit Votes Rewards </SC.SubmitButton>
            </>
    )
}

export default memo(VoteContent);



