
import MyAirdropsComponent from './airdrops';
import MyRewardsComponent from './rewards';
import MyVoteComponent from './votes';

const ProfileRoutes = () => {
    const routes = [
      {
        path: 'rewards',
        element: <MyRewardsComponent/>,
      },
      {
        path: 'votes',
        element: <MyVoteComponent/>,
      },
      {
        path: 'airdrops',
        element: <MyAirdropsComponent/>,
      }
    ];
    return routes;
  };
  
  export default ProfileRoutes;