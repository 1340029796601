import styled, { css } from "styled-components";
import { Row, Col } from "reactstrap";
import { Menu } from "@progress/kendo-react-layout";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { ShadeColor } from "@shade-color";

export const HeaderWrapper = styled(Row)`
    height: 100%;
    ${({ theme }) => css`
    background: ${theme.colorBodyBgDark};
    color: ${theme.colorBtnText};
    @media ${theme.tablet} {
        height: auto;
       }
    `}
`;

export const LogoContainer = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: center;
    ${ ({theme}) => css`
    @media ${theme.tablet} {
        padding: 1rem 0;
        height: 60%;
       }
    `}
`;

export const MainLogo = styled.img`
    width: auto;
    height: 55px;
`;

export const NavContainer = styled(Col)`
    display: flex;
    align-items: center;
    padding: 1rem;
    ${({ theme }) => css`
    .k-item{
        color:${theme.colorBodyTextLight} !important;
    }
    &:active {

    }
    @media ${theme.tablet} {
        height: 40%;
        padding-top: 0px;
        padding-left: 22px;
        flex-direction: column;
        justify-content: center;
       }
    `}
`;

export const StyledMenu = styled(Menu)`
    .k-menu-item.k-disabled{
        display: none;
    }
    ${({theme}) => css`
        @media ${theme.tablet} {
            display:none;
        }
    `}
`;

export const StyledMenuButton = styled.button`
    display: none;
    ${({theme}) => css`
        @media ${theme.tablet} {
            display: block;
            span{
                font-size: 25px;
            }
        }
    `}
`;
export const StyledPanelBarItem = styled(PanelBarItem)`
    ${({theme}) => css`
        @media ${theme.tablet} {
            // display: block;
        }
    `}
`;

export const LoginContainer = styled.div`
    margin-left: auto;
    padding-right: 1rem;
    ${({ theme }) => css`
    @media ${theme.tablet} {
        display: none;
       }
    `}
`;
export const MobileLoginContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    ${({ theme }) => css`
    @media ${theme.tablet} {
       }
    `}
`;

