import { useSelector } from "react-redux";

export const GetSelectedCyptoFromReducer =()=> {
    const getSelectedCyptoData = useSelector((state : {votingReducer : any}) => state?.votingReducer.selectedList)
    return getSelectedCyptoData;
}
export const GetAllCyptoFromReducer =()=> {
    const getAllCyptoCyptoData = useSelector((state : {votingReducer : any}) => state?.votingReducer.allcrypto)
    return getAllCyptoCyptoData;
}
