import styled, { css } from "styled-components";
export const LoginInputContainer = styled.div`
    ${({ theme }) => css`
        border-bottom: 1px solid ${theme.colorBodyBgDark};
    `}
    padding: 1rem 1rem 2rem 1rem;
`;
export const LoginFormInputWrapper = styled.div`
    // font-size: 30px !important;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    padding: 1rem 1rem;
    ${({ theme }) => css`
        input{
            border: none;
            border-bottom: 1px solid ${theme.colorBodyBgDark};
            border-radius: 0;
        }
    `}
    svg{
        font-size: 25px !important; 
    }
    .k-input:focus{
        box-shadow: none;
    }
    .k-form-field{
        width: 100%;
    }
`;
export const LoginButtonContainer = styled.div`
    display: flex;
    padding: 0 1rem;
    justify-content: space-between;
`;
