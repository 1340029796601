import { useEffect, memo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Grid, GridColumn } from "@progress/kendo-react-grid";

import { WindowData } from './constant'
import {
    GetSelectedCyptoFromReducer,
    GetAllCyptoFromReducer
} from '../../../voting/cryptoDetails/voteWindow/selectedCryptoList.selector';
import { Title, SubTitle } from '@core-primitives'
import * as SC from './voteTable.styled'


const MyVotesTableComponent = ({ tableVoteData }: any) => {
    const [tableData, setTableData] = useState<any[]>([])
    const voteData: any = GetSelectedCyptoFromReducer();
    const allCyptopList: any = GetAllCyptoFromReducer();
    const filteredData = Object.values(voteData).map((item: any) => ({ rank: item.rank, 'coinName': item['coinName'], 'rating': item['votingDominance'] }))

    useEffect(() => {
        setTableData(filteredData)
    }, [])
    useEffect(() => {
        setTableData((prev: any) => [...prev, ...tableVoteData])
    }, [tableVoteData])

    console.log('tableData', tableData)
    return (
        <SC.VoteTableContainer>
            <SC.VoteTableTitle>
                My Top 18
            </SC.VoteTableTitle>
            {tableData.length > 0 ? <Grid data={tableData}>
                <GridColumn field={WindowData[1]} title={WindowData[1]} />
                <GridColumn field={WindowData[0]} title={WindowData[0]} />
                <GridColumn field={'rating'} title={WindowData[2]} />
            </Grid>
                :
                <div>
                    Add Your Votes by clicking vote
                </div>
            }
        </SC.VoteTableContainer>
    )
}

export default MyVotesTableComponent
