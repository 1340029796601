import styled, { css } from "styled-components";


export const MyVoteTab = styled.div<{ backgroundChanged?: any }>`
    top: 90px;
    right: -88px;
    width: 120px;
    transform: rotate(90deg);
    // writing-mode: vertical-rl;
    transform: rotate(90deg);
    position: absolute;
    white-space: nowrap;
    border-radius: 10px 10px 3px 3px;
    ${({ theme }) => css`
        border: 1px solid ${theme.colorTitleText};
        background: ${theme.colorBodyBg};
        color: ${theme.colorTitleText};
        border-bottom: 0px;
        @media ${theme.tablet}{
        }
    `}
    ${({ theme, backgroundChanged }) => backgroundChanged && css`
        background: ${theme.colorBodyBgLight};
    `}
    h6{
        margin : 1rem .8rem;
    }
`;

export const MyVoteWrapper = styled.div<any>`
    position: fixed;
    top: 70px;
    left: -500px;
    width: 500px;
    height: calc(100vh - 70px);
    z-index: 92;
    display: flex;
    flex-direction: row;
    ${({ theme }) => css`
        border: 1px solid ${theme.colorTitleText};
        background: ${theme.colorBodyBg};
        @media ${theme.tablet}{
        }
    `}
    ${({ show }) => show && css`
            left: 0;
    `}
`;
export const MyVoteContent = styled.div`
    padding: 1rem;
    width: 500px;
    height: 600px;
`;
export const MyVoteInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;
