export const GET_ALL_DATA = 'GET_ALL_DATA';
export const getAllCyptoList = () => ({ type: GET_ALL_DATA });

export const SET_ALL_DATA = 'SET_ALL_DATA';
export const setAllCyptoList = (payload: any) => ({ type: SET_ALL_DATA, payload });

export const GET_DATA = 'GET_DATA';
export const getCyptoList = (payload: {query : string , page?: number}) => ({ type: GET_DATA, payload });

export const SET_DATA = 'SET_DATA';
export const setCyptoList = (payload: any) => ({ type: SET_DATA, payload });

export const SET_VOTE = 'SET_VOTE';
export const setVote = (payload: {key : string , value: any}) => ({ type: SET_VOTE, payload });

export const GET_REWARD_DATA = 'GET_REWARD_DATA';
export const getRewards = () => ({ type: GET_REWARD_DATA });

export const SET_REWARD_DATA = 'SET_REWARD_DATA';
export const setRewards = (payload: any) => ({ type: SET_REWARD_DATA, payload });

export const UPDATE_REWARD_DATA = 'UPDATE_REWARD_DATA';
export const updateRewards = (payload: any) => ({ type: UPDATE_REWARD_DATA, payload });