import {useEffect , memo} from 'react';
import { useDispatch} from 'react-redux'
import CryptoDetails from './cryptoDetails';
import CryptoLearning from './learningDetails';
import { getRewards } from './voting.actions';
import * as SC from './voting.styled'

const VotingComponent = () => {
    return (
        <SC.VotingWrapper>
            <SC.VotingContainer md={6} sm={12}>
                <CryptoDetails />
            </SC.VotingContainer>
            <SC.LearningContainer md={6} sm={12}>
                <CryptoLearning/>
            </SC.LearningContainer>
        </SC.VotingWrapper>
    )
};

export default memo(VotingComponent);
