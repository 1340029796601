import React,{FC, memo, useState} from 'react'
import { PopupWindow } from '@core-primitives'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faUser} from '@fortawesome/free-solid-svg-icons';

import * as SC from './login.styled'
import LoginFormComponent from './formDetails';
import { LoginPopUpComponentProps } from '@core-interface';

const LoginPopUpComponent: FC<LoginPopUpComponentProps> = ({showPopUp, onClose}) => {

    return (
        <SC.LoginComponentWrapper>
        <PopupWindow setShowPopup={showPopUp} onClose={onClose} initialHeight={450} noTitle={true}>
            <SC.PopUpWindowContainer>
                <SC.LoginTitleContainer>
                    <SC.LoginDataContainer clicked>
                        <FontAwesomeIcon icon={faUser} />
                        Login
                    </SC.LoginDataContainer>
                    <SC.LoginDataContainer>
                        <FontAwesomeIcon icon={faUserPlus} />
                        Register
                    </SC.LoginDataContainer>
                </SC.LoginTitleContainer>
                <SC.LoginDetailContainer>
                    <LoginFormComponent onClose={onClose}/>
                    <SC.LoginAdditionalLinkConatainer>
                        Forget <a href='#'>Password ?</a>
                    </SC.LoginAdditionalLinkConatainer>
                </SC.LoginDetailContainer>
            </SC.PopUpWindowContainer>
        </PopupWindow>
        </SC.LoginComponentWrapper>
    )
}

export default memo(LoginPopUpComponent)
