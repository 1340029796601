import React,{useState , useEffect} from 'react'
import * as SC from "./myvote.styled";
import { useSelector, useDispatch } from "react-redux";
import { GetSelectedCyptoFromReducer } from '../../pages/voting/cryptoDetails/voteWindow/selectedCryptoList.selector';
import VoteContent  from '../../pages/voting/cryptoDetails/voteWindow/voteContent';
import { PopupShade } from '../../core/primitives/window/window.styled';

const MyVoteComponent = () => {
    const dispatch = useDispatch();
    const [voteCount, setVoteCount] = useState<Number>(0);
    const [isShow, setIsShow] = useState<boolean>(false);
    const [background, setbackground] = useState<Boolean>(false);
    const voteData: any = GetSelectedCyptoFromReducer();

    useEffect(() => { 
        voteCount !== Object.values(voteData).length && setbackground(true);
        setVoteCount( Object.values(voteData).length);
    }, [Object.values(voteData).length]);
    const showVoteTable = () =>{
        setIsShow(prev => !prev)
    }
    return (
        <>
        {isShow && <PopupShade/>}
        <SC.MyVoteWrapper show={isShow}>
            <SC.MyVoteInner>
                <SC.MyVoteContent>
                    <VoteContent onSubmit={()=>setIsShow(false)}/>
                </SC.MyVoteContent>
                <SC.MyVoteTab backgroundChanged={background} onClick={showVoteTable}>
                    <h6>My votes ({voteCount})</h6>
                </SC.MyVoteTab>
            </SC.MyVoteInner>
        </SC.MyVoteWrapper>
        </>
    )
}

export default MyVoteComponent
