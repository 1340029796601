import styled, { css } from "styled-components";
import { ButtonComponent, Image } from "@core-primitives";
import { Title, SubTitle } from '@core-primitives'
import { ClaimDropDownIconProps } from "@core-interface"
import { CoinWhite, Ethereum, Bitcoin } from 'core-img'

export const ClaimPopupContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center; 
    .rightContent{
        flex: 1;
        padding: 0 10px;
    }
    .leftContent{
        flex: 1;
        border-left: 3px solid #f5f5f5; 
        padding: 0 0px 0 40px;
    }
    ${({theme}) => css`
    @media ${theme.tablet} {
        flex-direction: column;
        .leftContent, .rightContent{
            padding: 0;
            border: none;
            width: 100%;
        }
    }
`}
`;
export const ClaimPopupImageContainer = styled.div`
    ${({ theme }) => css`
        background-color: ${theme.colorBodyBgDark};
        border-radius: .5rem;
        display: flex;
        justify-content: center;
        align-items: center; 
    `}
    width: 100%;
    height: 4rem;
`;
export const ClaimPopupImage = styled.div`
    width: 3rem;
    height: 3rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${CoinWhite})
`;

export const ClaimPopupTitle = styled(Title)`
    padding-top: 1rem;
    text-align: center;
`;

export const ClaimPopupText = styled.div`
    ${({ theme }) => css`
        text-align: center;
        font-weight: 700;
        padding-bottom: 1.5rem;
        a{
            text-decoration: none;
            color: ${theme.colorLightText};
        }
    `}
`;
export const ClaimDropDownContainer = styled.div`
    ${({ theme }) => css`
        width: 100%;
        .k-input-value-text{
            display: flex;
            justify-content: center;
        }
    `}
`;
export const CailmFormContainer = styled.div`
    width:100%;
`;

export const CailmFormInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    padding: 1rem 0;
    ${({ theme }) => css`
        input{
            border: none;
            border-bottom: 1px solid ${theme.colorBodyBgDark};
            border-radius: 0;
            font-size: 15px !important;
        }
    `}
    svg{
        font-size: 25px !important; 
    }
    .k-input:focus{
        box-shadow: none;
    }
    .k-form-field{
        width: 100%;
    }
`;
export const ClaimButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    button{
        width:100%; 
    }
`;
export const ClaimTextContainer = styled.div`
    display: flex;
    padding-top: 1rem;
    justify-content: center;
`;
export const ClaimInfomationContainer = styled.div`
    width: 100%;
    font-size: 14px !important;
    padding: 1rem 0;
    line-height: 1.5rem;
    ul{
        margin-bottom: 0;
    }
    li{
        padding-top: .5rem;
    }
    ol{
        margin-bottom: 0;
        padding: 0;
        padding-left: 5rem;
    }
`;
export const DropDownItemContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .5rem
`;
export const DropDownIcon = styled.div<ClaimDropDownIconProps>`
    width: 2rem;
    height: 2rem;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    ${({theme, icon})=> icon == 'digits' && css`
        background-image: url(${CoinWhite});
    `}
    ${({theme, icon})=> icon == 'ethereum' && css`
        background-image: url(${Ethereum});
    `}
    ${({theme, icon})=> icon == 'bitcoin' && css`
        background-image: url(${Bitcoin});
    `}
`;


