import { FC, memo, useState } from "react"
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    Form,
    FormRenderProps,
    FormElement,
    Field
} from "@progress/kendo-react-form"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock} from '@fortawesome/free-solid-svg-icons';

import { fieldElementProps } from "./constant"
import { LoginFormComponentProps, OnSubmitProps } from '@core-interface';
import { ButtonComponent, FormInput } from '@core-primitives';
import * as SC from './formDetails.styled'
import { setLoginData } from "../login.actions";


const LoginFormComponent: FC<LoginFormComponentProps>  = ({onClose}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fliedProps: any = fieldElementProps({ FormInput })
    const loginDetailChecker = (data: OnSubmitProps) => {
        if(data.loginEmail == 'admin' && data.password == 'admin'){
            onClose()
            navigate('/profile/rewards')
            dispatch(setLoginData({email: 'admin@gmail.com', userName: 'admin', password: 'admin'}))
        }
    }
    const onSubmit = (data: any) => {
        loginDetailChecker(data)
    }
    
    return (
        <SC.LoginInputContainer>
            <Form onSubmit={onSubmit} render={
                (formRenderProps: FormRenderProps) => (
                    <FormElement >
                        <SC.LoginFormInputWrapper>
                            <FontAwesomeIcon icon={faEnvelope}/>
                            <Field
                                {...fliedProps['loginEmail']}
                            />
                        </SC.LoginFormInputWrapper>
                        <SC.LoginFormInputWrapper>
                            <FontAwesomeIcon icon={faLock}/>
                            <Field
                                {...fliedProps['password']}
                            />
                        </SC.LoginFormInputWrapper>
                        <SC.LoginButtonContainer>
                            <ButtonComponent
                                type={'highlight'}
                                disabled={!formRenderProps.allowSubmit}
                            >
                                Login
                            </ButtonComponent>
                            <ButtonComponent
                                type={"info"}
                                onClick={onClose}
                            >
                                Close
                            </ButtonComponent>
                        </SC.LoginButtonContainer>
                    </FormElement>
                )} />
        </SC.LoginInputContainer>
    )
}

export default memo(LoginFormComponent)
