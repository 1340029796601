import { ofType, combineEpics } from 'redux-observable';
import { switchMap, map, groupBy, mergeMap, tap } from 'rxjs/operators';
import { httpGet } from '@core-http';
import {
  GET_DATA,
  GET_ALL_DATA,
  GET_REWARD_DATA,
  setCyptoList,
  setAllCyptoList,
  setRewards
} from './voting.actions';

export const epicGetResults = (action$ :any, state$: any) => {
  return action$.pipe(
    tap(result => console.log('result',result)),
    ofType(GET_DATA),
    switchMap(({ payload : {query} }) =>
      httpGet({
        call: `CyptoData?${query}`,
      }).pipe(map((result) => setCyptoList(result)))
    )
  );
};

export const epicAllGetResults = (action$ :any, state$: any) => {
  return action$.pipe(
    tap(result => console.log('result',result)),
    ofType(GET_ALL_DATA),
    switchMap(() =>
      httpGet({
        call: `CyptoData`,
      }).pipe(map((result) => setAllCyptoList(result)))
    )
  );
};

export const epicGetAllRewardsResults = (action$ :any, state$: any) => {
  return action$.pipe(
    tap(result => console.log('result',result)),
    ofType(GET_REWARD_DATA),
    switchMap(() =>
      httpGet({
        call: `MyCoins`,
      }).pipe(map((result: any) => setRewards(result.mycoin)))
    )
  );
};

const ResultEpic = combineEpics(
     epicGetResults,
     epicAllGetResults,
     epicGetAllRewardsResults
);

export default ResultEpic;