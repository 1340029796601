import styled, { css } from 'styled-components';
import { PopupWindowStyledProps } from "@core-interface";
import { Window} from "@progress/kendo-react-dialogs";
import ButtonComponent from "../button";
import { ShadeColor } from '@shade-color';


export const PopupWindow = styled(Window)<PopupWindowStyledProps>`
    z-index: 92;
    .k-i-window-minimize{
        display: none;
    }
    .k-i-window-maximize{
        display: none;
    }
    ${({theme})=> css`
        .k-dialog-titlebar{
            background-color: ${theme.colorPopupWindowTitleBar}
        }
        @media ${theme.tablet} {
            width: calc(100% - 0.5rem) !important; 
            margin: 0.25rem;
            z-index: 94 !important;
            height: min-content !important;
            }
        `}
        ${({theme, noTitle})=> noTitle && css`
        .k-dialog-titlebar{
            display: none;
        }
    `}
    `

export const PopupShade = styled.div`
    width: 100%;
    height: 100%;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 91;
        ${ ({ theme }) => css`
            background-color: ${ShadeColor(theme.colorPopupWindowTitleBar, 40)};
        `}
`;