import styled, { css } from "styled-components";
import { Row, Col } from "reactstrap";
import { MobileSponser, SponserBanner, CoinLight } from 'core-img'

import { ButtonComponent,Image } from "@core-primitives";

export const RewardsContainer = styled(Row)`
    padding: 2rem 1rem;
    ${({theme})=> css`
        @media ${theme.tablet} {
            padding: 1rem .5rem;
            width: auto;
            flex-direction: column-reverse; 
        }
    `}
`; 
export const RewardsClaimContainer = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export const RewardsClaimWrapper = styled.div`
    padding: 2rem 1rem;
    display: flex;
    align-items: center;
    gap:.5rem
`;
export const RewardsCoinImage = styled.div`
    background-repeat: no-repeat;
    background-size: contain;
    height: 3rem;
    width: 3rem;
    background-image: url(${CoinLight});
`;

export const RewardsClaimButton = styled(ButtonComponent)`
    margin-bottom: 1rem;
    .k-button-text{
        font-size: 25px;
        padding: 0.2rem 1rem;
    }
`;
export const RewardsMembershipText = styled.div`
    ${({theme})=> css`
        font-weight: 700;
        padding-top: 1.5rem;
        a{
            text-decoration: none;
            color: ${theme.colorLightText};
        }
        @media ${theme.tablet} {
            text-align: center;
        }
    `}
`;

export const RewardsRefferalShare = styled.div`
    ${({theme})=> css`
        padding: 1rem 0;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        svg{
            font-size: 25px;
            color: ${theme.colorBodyBgDark};
        }
    `}
`;
export const RewardsAddContainer = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center;  
`;
export const RewardsSponserImage = styled.div`
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 1.5rem;
    width: 15rem;
    height: 30rem;
    background-image: url(${SponserBanner});
    ${({theme})=> css`
    @media ${theme.tablet} {
        background-image: url(${MobileSponser});
        margin: 0 0 1rem 0;
        width: 100%; 
        height: 12rem;    
    }
`}
`;

