import styled, { css } from "styled-components";
import { Row, Col } from "reactstrap";
import {ProfileNavigatorListItemStyledProps} from '@core-interface'

export const ProfileNavigatorContainer = styled(Row)`
    width: 100%;
    ${({theme})=> css`
    @media${theme.tablet} {
        width: auto;
       }
`}
`;

export const ProfileNavigatorList = styled(Col)`
    height: 100vh;
    ${({theme})=> css`
        background-color: ${theme.colorNavigationBg};
        @media${theme.tablet} {
            display: none;
           }
    `}
`;
export const ProfilePanelNavigator = styled(Col)`
    ${({theme})=> css`
        background-color: ${theme.colorNavigationBg};
        padding: 1rem 0;
        display: none;
        @media${theme.tablet} {
            display: unset;
            display: flex;
            justify-content: center;
            .k-picker{
                width: 90%;
            }
            span{
                display: flex;
                justify-content: center;
            }
           }
    `}
`;
export const ProfileNavigatorListItem = styled.div<ProfileNavigatorListItemStyledProps>`
    margin: .5rem 0 0 1rem;
    font-weight: 500 !important;
    text-align: center;
    ${({theme})=> css`
    padding: 0.5rem 2rem;
        &:hover{
            color: ${theme.colorBtnText};
            background-color: ${theme.colorBtnBgLight};
            border-radius: .5rem;
        }
    `}
    ${({theme, selected})=> selected && css`
        // color: ${theme.colorBtnText};
        background-color: ${theme.colorBtnBgLight};
        border-radius: .5rem;
    `}
`;

export const ProfileNavigatorPage = styled(Col)`
    height: 100vh;
    ${({theme})=> css`
        @media${theme.tablet} {
            width: auto;
        }
    `}
`;