import { SET_LOGIN_DATA } from "./login.actions";
import { LoginReducerProps } from '@core-interface'

const initState: LoginReducerProps = {
    loginData: null
};

const loginReducer = (state = initState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case SET_LOGIN_DATA:
            return {
                ...state,
                loginData: payload,
            };
        default:
            break;
    }
    return state;
}

export default loginReducer;