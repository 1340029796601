import {ProfileNavigationType} from '@core-interface'
  
export const profileNavigation = (): Array<ProfileNavigationType> => {
    return [
      {
        text: 'My Rewards',
        id: 'myRewards',
        url: 'rewards',
      },
      {
        text: 'My Votes',
        id: 'myVotes',
        url: 'votes',
      },
      {
        text: 'My Airdrops',
        id: 'myAirdrops',
        url: 'airdrops',
      }
    ];
  };
  