import styled, { css } from "styled-components";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Row, Col } from "reactstrap";

import { ButtonComponent, SearchComponent} from "@core-primitives";

export const InputContainer = styled.div`
    align-items: center;
    padding: .5rem ;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    ${({theme}) => css`
        @media ${theme.tablet}{
            form{
                display: flex;
                justify-content: center;
                width: 100%;
            }
            button{
                margin: .5rem 0;
            }
        }
    `}
`;

export const NavData = styled(Col)`
`;

export const VotingGrid = styled(Grid)`
    background: inherit;
`;

export const VotingGridColumn = styled(GridColumn)`

`;

export const SearchCryptoButton = styled(ButtonComponent)`
    width: 100%;
    margin-bottom: 2rem;
    white-space: normal;
`;

export const RankButton = styled(ButtonComponent)`
    display: none;
    ${({theme}) => css`
        @media ${theme.tablet}{
            display: unset;
        }
    `}
`;

export const VotingSearch = styled(SearchComponent)`
`;

export const SubmitButton = styled(ButtonComponent)`
    width: 100%;
    height: 2.5rem;
    margin: 1rem 0;
    font-weight: 700;   
    white-space: normal;
    span{
        display: flex;
        flex-direction: column;
    }
`;
export const ReawrfInfoText = styled.div`
    padding-top 1rem;
    text-align: center;
    ${({theme})=> css`
        color: ${theme.colorBodyBgDark};
        font-weight: 500;
    `}
`;
export const VoteAddButton = styled(ButtonComponent)`
    margin-left: 10px;
    ${({theme})=> css`

    `}
`;



