import { useEffect, memo, useState} from 'react'
import * as SC from './votes.styled'
import { Title, SubTitle } from '@core-primitives'
import MyVotesTableComponent from './voteTable'
import SearchVoteComponent from './serachVote'

const MyVoteComponent = () => {
    const [tableData, setTableData] = useState<any[]>([])

    return (
        <SC.VoteContainer>
            <SC.VoteMainTitlte md={12}>
            </SC.VoteMainTitlte>
            <SC.MyVoteTable md={6}>
                <MyVotesTableComponent tableVoteData={tableData}/>
            </SC.MyVoteTable>
            <SC.MyVoteTable md={6}>
                <SearchVoteComponent setTableData={setTableData} tableData={tableData}/>
            </SC.MyVoteTable>
        </SC.VoteContainer>
    )
}

export default MyVoteComponent
