import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faParachuteBox} from '@fortawesome/free-solid-svg-icons';

import * as SC from './airdrop.styled'
import { Title, SubTitle } from '@core-primitives'
import { Binance } from 'core-img'


const MyAirdropsComponent = () => {
    return (
        <SC.AirdropContainer>
            <SC.AirdropSummery md={6}>
                <SC.AirdropteTitle>
                    Airdrop Summery
                </SC.AirdropteTitle>
                <SC.AirdropSummeryButton type={'info'}>
                    <FontAwesomeIcon icon={faParachuteBox}/>
                    MY AIRDROPS
                </SC.AirdropSummeryButton>
                <SC.AirdropSummeryAllButton type={'highlight'}>
                    ALL AIRDROPS
                </SC.AirdropSummeryAllButton>
            </SC.AirdropSummery>
            <SC.AirdropUpComing md={6}>
                <SC.AirdropteTitle>
                    Upcoming Airdrop
                </SC.AirdropteTitle>
                <SubTitle>
                    Binance USD (BUSD)
                </SubTitle>
                <SC.BinananceLogoContainer />
                <SC.AirdropUpComingText>
                    <br/>
                        Only HERO members are eligible <br/>
                        Snapshot On May 31st, 10 AM CEST <br/>
                        Period: June 1st, 5 PM CEST – June 5th, 1 PM CEST
                     <br/>
                </SC.AirdropUpComingText>
                <SC.AirdropUpHeroButton>
                    Become a HERO
                </SC.AirdropUpHeroButton>
            </SC.AirdropUpComing>
        </SC.AirdropContainer>
    )
}

export default MyAirdropsComponent
