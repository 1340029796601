import { PopupWindowProps } from "@core-interface";
import { useEffect, useState } from "react";
import * as SC from './window.styled';

const PopupWindow = ({ children, setShowPopup, onClose, initialHeight, initialTop, initialWidth }: PopupWindowProps) => {

    return (
        <>
            {setShowPopup &&
                <>
                <SC.PopupShade/>
                <SC.PopupWindow 
                    onClose={onClose} 
                    initialWidth={initialWidth ? initialWidth : 500} 
                    initialHeight={initialHeight ? initialHeight: 600} 
                    initialTop={initialTop ? initialTop : 100}
                >
                    {children}
                </SC.PopupWindow>
                </>
            }
        </>
    )
}

export default PopupWindow
