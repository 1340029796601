import React, { memo, useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as SC from './header.styled'
import { MainLogo } from '../../core/img';
import { navigation, navigationMobile } from './header.constant'
import {
    PanelBarItem,
    PanelBarSelectEventArguments
} from "@progress/kendo-react-layout";
import { ButtonComponent, StyledPanelBar } from '@core-primitives';
import LoginPopUpComponent from '../login'
import { GetLoginDataFormReducer } from '../login/login.selector';
import { setLoginData } from '../login/login.actions';

const HeaderComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [show, setShow] = useState<boolean>(false);
    const [loginText, setLoginText] = useState<string>('Login');
    const [navigationData, setnavigationData] = useState<any>(navigation);
    const [showPopUp, setShowPopUp] = useState<boolean>(false);
    const anchor = useRef<HTMLButtonElement>(null);
    const userLoginData: any = GetLoginDataFormReducer()

    useEffect(() => {
        if (!userLoginData) return;
        const updateNavData = navigationData.map((navData: any) => {
            if (navData.text == 'Profile') {
                return { ...navData, disabled: false }
            }
            return navData
        })
        setnavigationData(updateNavData)
    }, [JSON.stringify(userLoginData)])

    const menuOnSelect = ({ item }: any) => {
        if (item.link) {
            navigate(item.link)
        }
    }

    const menuMobileOnSelect = ({ target: { props: { title } } }: PanelBarSelectEventArguments) => {
        const navData = navigationMobile.find(({ text }: any) => text == title)
        if (navData?.link) {
            navigate(navData?.link)
        }
    }
    const handleClick = () => {
        setShow(!show);
    };
    const loginPopUp = () => {
        setShowPopUp(!showPopUp);
    };
    const logoutPopUp = () => {
        dispatch(setLoginData(null))
    };
    const navigateToHome = () => {
        navigate('/')
    };

    return (
        <SC.HeaderWrapper>
            <SC.LogoContainer md={3} sm={12}>
                <SC.MainLogo src={MainLogo} onClick={navigateToHome}/>
            </SC.LogoContainer>
            <SC.NavContainer md={9} sm={12}>
                <SC.StyledMenu items={navigationData} onSelect={menuOnSelect} />
                <SC.StyledMenuButton
                    className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base overflow-button"
                    ref={anchor}
                    onClick={handleClick}
                >
                    <span className="k-icon k-i-menu" />
                </SC.StyledMenuButton>
                {show && <>
                    <StyledPanelBar onSelect={menuMobileOnSelect}>
                        {navigationData.filter((navItem: any) => !navItem.disabled)
                            .map((navItem: any) =>
                                <PanelBarItem key={navItem.text} title={navItem.text} >
                                    {navItem.items && navItem.items.map((navData: any) =>
                                        <PanelBarItem key={navData.text} title={navData.text} />
                                    )}
                                </PanelBarItem>
                            )}
                    </StyledPanelBar>
                    <SC.MobileLoginContainer>
                        {!userLoginData ?
                            <ButtonComponent type={'highlight'} onClick={loginPopUp}>
                                {loginText}
                            </ButtonComponent> :
                            <ButtonComponent type={'highlight'} onClick={logoutPopUp}>
                                Logout
                            </ButtonComponent>
                        }
                        <ButtonComponent type={'highlight'} onClick={() => setShow(!show)}>
                            Close
                        </ButtonComponent>
                    </SC.MobileLoginContainer>
                </>}
                <SC.LoginContainer>
                    {!userLoginData ?
                        <ButtonComponent type={'highlight'} onClick={loginPopUp}>
                            {loginText}
                        </ButtonComponent> :
                        <ButtonComponent type={'highlight'} onClick={logoutPopUp}>
                            Logout
                        </ButtonComponent>
                    }
                </SC.LoginContainer>
            </SC.NavContainer>
            <LoginPopUpComponent showPopUp={showPopUp} onClose={loginPopUp} />
        </SC.HeaderWrapper>
    )
}

export default memo(HeaderComponent);
