import { LoginDataContainerProps } from "@core-interface";
import styled, { css } from "styled-components";

export const LoginComponentWrapper = styled.div`
    .k-dialog-titlebar{
        display: none !important;
    }
`
export const PopUpWindowContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const LoginTitleContainer = styled.div`
    width: 90%;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    height: 4rem;
    border-radius: .2rem;
    box-shadow: 2px 2px 5px #888888;
    ${({theme})=> css`
        background-color: ${theme.colorBodyBgDark};
    `}
`   
export const LoginDataContainer = styled.div<LoginDataContainerProps>`
    display: flex;
    width: 50%;
    height: auto !important;
    margin: 0.2rem 0.5rem;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    height: auto;
    flex-direction: row;
    height: 4rem;
    border-radius: .2rem;
    ${({theme})=> css`
        color: ${theme.colorBodyBg};;
    `}
    ${({theme, clicked})=> clicked && css`
        background-color: ${theme.colorBodyBgLight};
    `}
` 
export const LoginDetailContainer = styled.div`
    height: 100%;
    width: 100%;
    border-radius: .2rem;
    box-shadow: 2px 2px 5px #888888;
`
export const LoginAdditionalLinkConatainer = styled.div`
    display: flex;
    padding: 1rem   ;
    width: 90%;
    justify-content: center;
    gap: 0.5rem;
    a{
        text-decoration: none;
    }
`;