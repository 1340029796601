import React,{memo} from 'react'

import {
    Form,
    FormRenderProps,
    FormElement,
    Field
} from "@progress/kendo-react-form"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faLocationDot} from '@fortawesome/free-solid-svg-icons';
import { fieldElementProps } from "./constant"
import * as SC from './cailmPopup.styled'
import {FormInput} from '@core-primitives'
import { ButtonComponent } from '@core-primitives';

const CailmFormComponent = () => {
    const fliedProps: any = fieldElementProps({ FormInput })
    const onSubmit = (data: any) => {
        console.log(data)
    }
    return (
        <SC.CailmFormContainer>
            <Form onSubmit={onSubmit} render={
                (formRenderProps: FormRenderProps) => (
                    <FormElement >
                        <SC.CailmFormInputWrapper>
                            <FontAwesomeIcon icon={faLocationDot}/>
                            <Field
                                {...fliedProps['connetMetaMask']}
                            />
                        </SC.CailmFormInputWrapper>
                        <SC.ClaimButtonContainer>
                            <ButtonComponent
                                type={'highlight'}
                                disabled={!formRenderProps.allowSubmit}
                            >
                                Connect MetaMask
                            </ButtonComponent>
                        </SC.ClaimButtonContainer>
                    </FormElement>
                )} />

            <Form onSubmit={onSubmit} render={
                (formRenderProps: FormRenderProps) => (
                    <FormElement >
                        <SC.CailmFormInputWrapper>
                            <FontAwesomeIcon icon={faCoins}/>
                            <Field
                                {...fliedProps['numberOfPoints']}
                            />
                        </SC.CailmFormInputWrapper>
                        <SC.ClaimButtonContainer>
                            <ButtonComponent
                                type={'highlight'}
                                disabled={!formRenderProps.allowSubmit}
                            >
                                withdraw
                            </ButtonComponent>
                        </SC.ClaimButtonContainer>
                    </FormElement>
                )} />
        </SC.CailmFormContainer>
    )
}

export default memo(CailmFormComponent)
