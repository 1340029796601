import styled, { createGlobalStyle, css } from "styled-components";
import { device } from './constant'

export default createGlobalStyle`
    *{
    font-size: 17px;
    box-sizing: border-box !important;
    } 

`;
