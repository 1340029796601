
const emailRegex = new RegExp(/\S+@\S+\.\S+/);

const emailValidator = (value: string) => emailRegex.test(value) ? "" : "Please enter a valid email.";
const inputValidator = (value: boolean) => (!value ? "* required" : "");

export const fieldElementProps = ({ FormInput}: any) => {
  return (
    {
      loginEmail: {
        id: 'loginEmail',
        label: 'loginEmail',
        name: 'loginEmail',
        component: FormInput,
        placeholder: 'Your Email',
        validator: inputValidator,
      },
      password: {
        id: 'password',
        label: 'password',
        name: 'password',
        component: FormInput,
        placeholder: 'Your Password',
        validator: inputValidator,
      }
    })
}