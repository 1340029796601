import React,{useEffect} from 'react';
import { useDispatch} from 'react-redux'
import './App.css';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from "react-router-dom";
import { GlobalStyle, theme } from '@core';
import { MainComponet } from '@mod';
import { getRewards } from './pages/voting/voting.actions';

console.log('this  is theme', theme);

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(getRewards())
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <GlobalStyle/>
        <MainComponet />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
