import styled, { css } from "styled-components";
import { Rating, } from "@progress/kendo-react-inputs";

const StyledRating = styled(Rating)`
  ${({ theme }) => css`
  .k-rating-item{
    color: ${theme.colorBodyTextLight};
    padding: 0;
  }
    .k-state-selected {
        color: ${theme.colorBtnBgLight};
    }
    .k-rating-container .k-rating-item .k-icon {
      font-size: 20px;
  }
  `}
`;
export default StyledRating
