import React,{memo, useCallback} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { DropDownList } from "@progress/kendo-react-dropdowns";


import { ProfileNavigationType } from '@core-interface'
import { StyledPanelBar } from '@core-primitives';
import { Outlet } from "react-router-dom";
import * as SC from './profile.styled';
import { profileNavigation } from './constant';

const ProfileComponent = () => {
    const navigate = useNavigate();
    let location = useLocation()
    const status = location.pathname.split('/')[2]
    const navigationPath = useCallback(
        ( url: any) => {
          navigate(url, { replace: true });
        },
        [navigate]
      );
    const onchange = ({value}: any)=>{
        navigate(value.url, { replace: true });
    }
    return (
        <SC.ProfileNavigatorContainer>
            <SC.ProfileNavigatorList md='3'>
                { profileNavigation().map((profileItem: ProfileNavigationType)=>
                    <SC.ProfileNavigatorListItem 
                        key={profileItem.id} 
                        onClick={()=>navigationPath(profileItem.url)} 
                        selected={ profileItem.url == status ? true : false}>
                        {profileItem.text}
                    </SC.ProfileNavigatorListItem>
                )}
            </SC.ProfileNavigatorList>
            <SC.ProfilePanelNavigator md='3'>
                <DropDownList
                        defaultValue={profileNavigation()[0]}
                        data={profileNavigation()}
                        textField={'text'}
                        onChange={onchange}
                />
            </SC.ProfilePanelNavigator>
            <SC.ProfilePanelNavigator>
            </SC.ProfilePanelNavigator>
            <SC.ProfileNavigatorPage md='9'>
               <Outlet/>
            </SC.ProfileNavigatorPage>
        </SC.ProfileNavigatorContainer>
    )
}

export default memo(ProfileComponent)