import styled, { css } from "styled-components";
import { Row, Col } from "reactstrap";

export const VoteContainer = styled(Row)`
    width: auto;
    padding: 1rem;
`;
export const VoteMainTitlte = styled(Col)`

`;
export const MyVoteTable = styled(Col)`
    padding: 1rem;
`;
export const MyVoteSerachList = styled(Col)`
    padding: 1rem;
`;