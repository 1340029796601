import { ButtonProps } from "src/core/interface";
import { Button } from '@progress/kendo-react-buttons'
import { ShadeColor } from '@shade-color';
import styled,{ css } from "styled-components";

export const StyledButton = styled(Button)<ButtonProps>`
    ${({ theme }) => css`
        padding: 0.3rem 1.5rem;
        flex-wrap: wrap;
        border-radius: 1rem;
        background: ${theme.colorBtnBg};
        color : ${theme.colorBtnText};
        &:hover {
            background: ${ShadeColor(theme.colorBtnBg,30)};
        }
    `}

    // ${({ type }) =>type === 'info' && css`
    //     // border-radius: 0.5rem; 
    // `}

    ${({ theme , type }) =>type === 'highlight' && css`
        background: ${theme.colorBtnBgLight};
        color : ${theme.colorBtnText};
        &:hover {
            background: ${ShadeColor(theme.colorBtnBgLight,30)};
        }
    `}
`
