import { memo} from 'react'
import {  PopupWindow} from '@core-primitives';

import VoteContent from './voteContent';


const VoteWindow = ({ setShow, onClose }: any) => {

    return (
        <PopupWindow setShowPopup={setShow} onClose={onClose}>
            <VoteContent onSubmit={onClose}/>
        </PopupWindow>
    )
}

export default memo(VoteWindow);


