import styled, { css } from "styled-components";

export const Title = styled.h3`
    font-weight: 900;
    ${({ theme }) => css`
    color: ${theme.colorTitleText};
    `}
`;

export const SubTitle = styled.h4`
    font-weight: 700;
    padding-top: 1.5rem;
    ${({ theme }) => css`
    color: ${theme.colorTitleText};
    `}
`;

