import styled, { css } from "styled-components";
import { Row, Col } from "reactstrap";

import { ShadeColor } from "@shade-color"; 

export const VotingWrapper = styled(Row)`
`;

export const VotingContainer = styled(Col)`
    padding: 4rem 1rem 0 4.5rem;
    ${({ theme }) => css`
    background : ${ShadeColor(theme.colorBodyBgDark, 180)};
    @media ${theme.tablet} {
        padding: 2rem;
    }
    `}
`;

export const LearningContainer = styled(Col)`
    padding: 4rem;
    ${({ theme }) => css`
    @media ${theme.tablet} {
        padding: 2rem;
    }
    `}
`;




