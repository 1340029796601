import { useState, useEffect, memo } from "react";
import { Grid, GridToolbar, GridColumn } from "@progress/kendo-react-grid";
import { useSelector, useDispatch } from "react-redux";
import  { size } from '../../../core/theme/constant'
import { deskTopColums, mobileColumns } from '../voting.contant'
import { ButtonComponent, StyledRating, Title } from '@core-primitives';
import * as SC from './cryptoDetails.styled'
import VoteWindow from "./voteWindow";
import { getCyptoFromReducer } from "./cryptoDetails.selector";
import { getCyptoList, setVote } from '../voting.actions';
import {MyVoteComponent} from "@mod"
import { useWindowSize, theme } from "@core"

const CryptoDetails = () => {
    const limit = 5;
    const {width} = useWindowSize();
    const dispatch = useDispatch();
    const [show, setShow] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [columns, setcolumns] = useState<any>(deskTopColums);
    const [searchText, setSearchText] = useState<string>('');

    useEffect(() => {
        dispatch(getCyptoList({query :`_page=${page}&_limit=${limit}&coinName_like=${searchText}`, page}));
    }, [page, searchText]);

    const cryptoDetails = useSelector(getCyptoFromReducer);
    
    useEffect(()=>{
        if(width && size.tablet >= width ){
            setcolumns(mobileColumns);
        }else {
            setcolumns(deskTopColums);
        }
    },[width])
    useEffect(()=>{
        console.log("this is columns", columns);
    },[columns.length])
    const rating = ({ dataItem }: any) => {
        return (
            <td>
                <StyledRating defaultValue={dataItem['votingDominance']} readonly={true} />
            </td>
        )
    }
    const votingButton = (e: any) => {
        return (
            <td>
                <ButtonComponent type="highlight" onClick={() => onVoteClick(e)}>Vote</ButtonComponent>
            </td>
        )
    }

    const showMore = () => {
        setPage(prev => prev + 1);
    }

    const onVoteClick = ({ dataItem }: any) => {
        const data = { key: dataItem['coinName'], value: dataItem }
        dispatch(setVote(data))
        setShow(true)
    }
    
    const onSearch = (value : string) => {
        setPage(1)
        setSearchText(value);
    }
    return (
        <>
            {show && <VoteWindow setShow={show} onClose={() => setShow(!show)} />}
            <MyVoteComponent/>
            <div>
                <Title>
                    Top 10 CyptoCurrency Voted
                </Title>
                <div>
                    New votings Results Every Friday 3PM CEST
                </div>
            </div>
            <SC.InputContainer>
                <SC.RankButton>TOP 10</SC.RankButton>
                <ButtonComponent>TOP 20</ButtonComponent>
                <ButtonComponent>TOP 50</ButtonComponent>
                <ButtonComponent>TOP 100</ButtonComponent>
                <SC.VotingSearch onSearch={onSearch}/>
            </SC.InputContainer>
            <SC.VotingGrid data={cryptoDetails}>
                {
                    columns.map((columnData: any)=> (
                    <GridColumn 
                        field={columnData.key} 
                        title={columnData.title}
                        cell={ eval(columnData.cell)}
                        width={columnData.width}
                    />)  
                    )
                }
                <GridColumn cell={votingButton} width="100px" />
            </SC.VotingGrid>
            <SC.SearchCryptoButton type={'info'} onClick={showMore}>Search More CryptoCurrencies</SC.SearchCryptoButton>
            <SC.SearchCryptoButton type={'info'}>Compare Crypto to Stock Indices and Commodities</SC.SearchCryptoButton>
        </>
    )
}

export default memo(CryptoDetails);
