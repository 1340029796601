import { AutoComplete } from "@progress/kendo-react-dropdowns";
import styled, { css } from "styled-components";
import { ShadeColor } from "@shade-color";

const PopupAutoComplete = styled(AutoComplete)`
    border-radius: 1.5rem;
    padding: 0.2rem .5rem !important;
    width: 15rem;
    margin: .5rem 0;
    ${({ theme }) => css`
    background: ${theme.colorBtnBg};
    color : ${theme.colorBtnText};
    &:placeholder {
    color: ${ShadeColor(theme.colorBtnBgLight,150)} !important;
    }
    `}
`;

export default PopupAutoComplete;