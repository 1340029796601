import styled, { css } from "styled-components";
import { PanelBar } from "@progress/kendo-react-layout";
import { ShadeColor } from "@shade-color";

const StyledPanelBar = styled(PanelBar)`
    ${({theme}) => css`
        margin: 1rem 0  ;   
        display: none;
        width: -webkit-fill-available;
        @media ${theme.tablet} {
            display: block;
            background: ${ShadeColor(theme.colorBodyTextLight, 20)} !important;
        }
        .k-link.k-state-selected{
            background: ${theme.colorBodyBgDark} !important;
            color: ${theme.colorBtnText} !important;
        }
        .k-link{
            background:  ${ShadeColor(theme.colorBodyTextLight, 80)};
            color: ${theme.colorBodyBgDark} !important;
            }
        }
    `}
`;

export default StyledPanelBar;