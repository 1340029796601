import styled, { css } from "styled-components";
import { Row, Col } from "reactstrap";

import { Title, SubTitle } from '@core-primitives'
import { ButtonComponent,Image } from "@core-primitives";
import { Binance } from 'core-img'


export const AirdropContainer = styled(Row)`
    padding: 2rem 1rem;
    ${({theme})=> css`
        @media ${theme.tablet} {
            width: auto; 
        }
    `}
`; 
export const AirdropSummery = styled(Col)`
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${({theme})=> css`
        @media ${theme.tablet} {
            width: auto; 
        }
    `}
`; 
export const AirdropUpComing = styled(Col)`
        padding: 1rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    ${({theme})=> css`
        @media ${theme.tablet} {
            width: 100%; 
        }
    `}
`; 
export const AirdropteTitle = styled(Title)`
    flex-direction: column;
    align-items: center;  
`;
export const BinananceLogoContainer = styled.div`
        background-repeat: no-repeat;
        background-size: contain;
        height: 5rem;
        width: 5rem;
        background-image: url(${Binance});
        margin-top: 1rem;
`;
export const AirdropUpComingText = styled.div`
        text-align: center;
        line-height: 2rem;
`;
export const AirdropUpHeroButton = styled(ButtonComponent)`
    margin-top: 1rem;
    width:90%;
`;
export const AirdropSummeryButton = styled(ButtonComponent)`  
    margin-top: 1rem;
    width:90%;
    .k-button-text{
        display: flex;
        gap: 1rem;
        align-items: center;
        padding: .5rem 0;
    }
    svg{
        font-size: 25px;
    }
`;
export const AirdropSummeryAllButton = styled(ButtonComponent)`
    margin-top: 1rem;
    width:90%;
`;
