
import { colors, size, device } from './constant'


export const theme = {
    colorBodyBgDark: colors.xyza,
    colorBodyBgLight: colors.ghrt,
    colorBtnBg: colors.xyza,
    colorBtnBgLight: colors.ghrt,
    colorBtnText: colors.bgjf,
    colorTitleText: colors.xyza,
    colorLightText: colors.ghrt,
    colorBodyTextLight: colors.fghr,
    colorNavigationBg: colors.fghr,
    colorPopupWindowTitleBar: colors.fghr,
    colorTextHover: colors.fged,
    colorBodyBg: colors.bgjf,
    borderColor: colors.hjgd,
    ...size,
    ...device,
};  

