import styled, { css } from "styled-components";
import { Row, Col } from "reactstrap";

export const LessonContainer = styled(Row)`
`;

export const LessonData = styled(Col)`
    margin-bottom: 2rem;
`;

export const VedioContainer = styled.div`
    border-readius: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${({theme})=> css`
        background: ${theme.colorBodyBgDark};
        width: 100%;
        padding: 4rem;
        border-radius: 0.2rem;
    `}
`;

export const VedioLogo = styled.img`
    width: auto;
    height: 55px;
`;

export const VedioText = styled.div`
    padding: 1rem;
    text-transform: uppercase;
    ${({theme})=> css`
        color: ${theme.colorBtnText};
    `}
`;