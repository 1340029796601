import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import { switchMap, tap } from 'rxjs/operators';

// import { setActionBussy } from './actions';

const httpPost = ({ call, data }: { call: any, data: any }) => {
  return httpCall('POST', call, data);
};

const httpGet = ({ call }: any) => {
  return httpCall('GET', call);
};

const httpDelete = ({ call }: any) => {
  return httpCall('DELETE', call);
};

const httpPut = ({ call, data }: { call: any, data: any }) => {
  return httpCall('PUT', call, data);
};

const httpCall = (type: any, call: any, data = null) => {
  //   setActionBussy(true);
  let request_url = process.env.REACT_APP_SERVER_PATH;
  let headers = {
    'Access-Control-Allow-Origin': 'https://api-saleplus.herokuapp.com',
    'Content-type': 'application/json',
    'X-RateLimit-Reset': 1454313600,
    'X-RateLimit-Remaining': 0,
    'X-RateLimit-Limit': 1000,
  };

  let jsonData = null;
  if (data) {
    jsonData = JSON.stringify(data);
  }

  const sessionString = localStorage.getItem('session');
  request_url = `${request_url}/${call}`;
  if (sessionString) {
    //const session = JSON.parse(sessionString);
    // headers = {
    //   ...headers,
    //   'Access-Control-Allow-Origin': 'https://api-saleplus.herokuapp.com',
    //   'X-requested-with': 'XMLHttpRequest',
    //   'X-USER-TOKEN': session.authentication_token,
    //   'X-USER-EMAIL': session.email,
    // };
  }

  return ajax({
    url: request_url,
    crossDomain: true,
    method: type,
    responseType: 'json',
    timeout: 0,
    headers,
    body: data ? jsonData : null,
    createXHR: function () {
      return new XMLHttpRequest();
    },
  }).pipe(
    // tap(result => setActionBussy(false)),
    switchMap(data => of(data.response)),
  );
};

const apiStatus = {
  success: 200,
};
export { httpPost, httpGet, httpDelete, httpPut, apiStatus };
