import { SearchStyled } from './search.styled'
import { SearchProps } from '@core-interface';

const SearchComponent = ({ onSearch }: SearchProps) => {
    const onHandleSubmit = (e: any) => {
        e.preventDefault();
        onSearch(e.target[0].value);
    };
    return (
        <form onSubmit={onHandleSubmit}>
            <SearchStyled type="text" placeholder="Search More CyptoCurencies..." />
        </form>
    );
}

export default SearchComponent
