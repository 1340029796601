import styled,{ css } from "styled-components"; 
import { Input } from "@progress/kendo-react-inputs";
import { ShadeColor } from "@shade-color";

export const SearchStyled = styled(Input)`
    border-radius: 1rem;
    padding: 0.3rem 1.5rem !important;
    width: 15rem;
    margin: .5rem 0;
    ${({ theme }) => css`
        background: ${theme.colorBtnBg};
        color : ${theme.colorBtnText};
        ::placeholder {
        color: ${ShadeColor(theme.colorBtnBgLight,150)};
        }
        font-size: 17px;
        @media ${theme.tablet}{
            margin: .5rem 0;
        }
    `}
`;
