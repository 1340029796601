import { AutoComplete } from "@progress/kendo-react-dropdowns"

export const deskTopColums = [
    {key: 'rank' , title:  'Rank', 'width': 60 } ,
    {key: 'pastRank' , title:  'Past Rank', 'width': 60 },
    {key: 'coinName' , title:  'Name', 'width': 120  },
    {key: 'coinPrice' , title:  'Price $', 'width': 110 },
    {key: 'votingDominance' , title:  'Voting Dominance', cell: 'rating', 'width': '195' }
]

export const mobileColumns = [
    {key: 'rank' , title:  'Rank', 'width': 60 } ,
    {key: 'coinName' , title:  'Name', 'width': 150 },
    {key: 'votingDominance' , title:  'Voting Dominance', cell: 'rating', 'width': 200 }
]



