import { ButtonProps } from 'src/core/interface';
import * as SC from './button.styled';

const ButtonComponent = (props: ButtonProps) => {
    return (
        <SC.StyledButton {...props}>{props.children}</SC.StyledButton>
    )
}

export default ButtonComponent

